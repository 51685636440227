
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        



































































.ag__links {
  // prettier-ignore
  @include fluid(padding-bottom, (xxs: 20px, m: 30px));

  @include mq('xl') {
    border-bottom: 1px solid $c-gray-light;
  }
}

.ag__links__filter {
  display: flex;
  align-self: center;
}

.ag__links__filter__select {
  border: 0;

  ::v-deep {
    .placeholder {
      color: $c-orange;
      font-size: 1.6rem;
    }

    .options {
      width: 8rem;
      border: 1px solid $c-orange;
      border-radius: 3px;
      box-shadow: 0 0 5px rgba($c-black, 0.2);
    }
  }
}

.ag__links__filter__reset {
  display: flex;
  align-self: center;
  width: 3rem;
  height: 3rem;
  margin-right: 0.5rem;
  padding: 1rem;
  background: transparent;
  border: 0;
  cursor: pointer;

  svg {
    width: 1rem;
    height: 1rem;
  }
}

.ag__links__title {
  @extend %fw-bold;
}

.ag__links__item {
  display: flex;
  justify-content: flex-start;
  margin-right: 2rem;

  ::v-deep &[class*='action--'][class*='--link']:not([class*='--btn']) {
    .action__label {
      color: $c-black;
    }

    &:hover {
      .action__label {
        color: $c-main-primary;
      }
    }
  }
}

.ag__date {
  @extend %fw-medium;

  display: block;
}
