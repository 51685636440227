
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        















































.select {
  position: relative;
  border-bottom: 0.1rem solid $c-gray-light;

  &.has-error {
    border-bottom: 0.1rem solid $c-red;
  }
}

.real-select {
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.label,
.option {
  width: 100%;
  padding: 0;
  background: none;
  border: 0;
  cursor: pointer;
}

.label {
  display: block;
  color: $c-orange;
  text-align: left;

  .has-error & {
    color: $c-red;
  }
}

.placeholder {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-decoration: none;

  &:hover {
    // stylelint-disable-next-line
    color: $c-gray !important;
  }
}

.options {
  position: absolute;
  z-index: 10;
  top: calc(100% + 5px);
  left: 0;
  height: auto;
  padding: 1rem 2rem;
  background: $c-white;
  border: 1px solid $c-black;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.2s;

  .is-open & {
    opacity: 1;
    visibility: visible;
  }
}

.option {
  display: flex;
  padding: 0.5rem 0 0.3rem;
  color: $c-gray;
  font-size: 1.8rem;
  text-decoration: none;

  &:hover {
    color: $c-black;
  }
}

.icon {
  width: 1rem;
  height: 0.6rem;
  margin-left: 0.5rem;
  transition: transform 0.2s;

  .is-open & {
    transform: scale(-1);
  }
}
